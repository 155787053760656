import TextArea from 'antd/lib/input/TextArea';
import * as mime from 'mime-types';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { getConfirmation, getDealByID, getDrawdownsByForwardID, getFullDealHeader, saveDealComments } from '../../../api/NetworkingDeals';
import useWindowSize from '../../../api/hooks/useWindowResize';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import AddEditOrderTemplate from '../../orders/ordersTemplate/AddEditOrderTemplate';

interface SummaryProps {
  dealHeaderID: number;
  useNewTradeButton?: boolean;
  useConfirmationButton?: boolean;
  onClickNewTrade?: Function;
  useDepositRequired?: boolean;
  useSaveTemplateButton?: boolean;
  saveTemplateModalProps?: SaveTemplateModalProps;
  onClickExit?: Function;
  showExitButton?: boolean;
}

interface SaveTemplateModalProps {
  ButtonLabel?: string;
  Modaltitle?: string;
}

const Summary: FunctionComponent<SummaryProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [deal, setDeal] = useState({} as any);
  const [anotherDeal, setAnotherDeal] = useState({} as any);  
  const [dealDrawdowns, setDealDrawdowns] = useState<any[]>([]);
  // ---  Modal Add Template -------------------------------------------------------------------r2d2--
  const [showAddLikeTemplateModal, setShowAddLikeTemplateModal] = useState(false);
  //--------------------------------------------------------------------------------------------------
  const [comments, setComments] = useState('');
  const [updateToken, setUpdateToken] = useState('');

  const [VATIncomingFunds, setVATIncomingFunds] = useState(0);

  const size = useWindowSize();

  useEffect(() => {
    if (props.dealHeaderID !== undefined && props.dealHeaderID > 0) {
      getDealByID(props.dealHeaderID).then((dealResponse: any) => {
        console.log(dealResponse);
        if (dealResponse !== undefined && dealResponse.deals != null && dealResponse.deals.length > 0) {
          setDeal(dealResponse.deals[0]);
          setVATIncomingFunds(dealResponse.deals[0].VAT > -1 ? dealResponse.deals[0].VAT : 0)
          setComments(dealResponse.deals[0]['External Comments']);
          if (dealResponse.deals[0]['Deal Type'] === 4) {
            getDrawdownsByForwardID(props.dealHeaderID).then((drawdownsResponse: any) => {
              console.log(drawdownsResponse);
              if (drawdownsResponse.totalCount > 0) {
                setDealDrawdowns(drawdownsResponse.deals);
              }
            });
          }
        }
      });
      getFullDealHeader(props.dealHeaderID).then((dealResponse: any) => {
        setAnotherDeal(dealResponse);
        setUpdateToken(dealResponse.UpdateToken);
      });
    }
  }, [props.dealHeaderID]);

  const GetIncomingData = (incomingFund: any) => {
    if (deal.VAT > -1) {
      let fee = 0;
      if (anotherDeal.DealFees !== null) {
        fee = anotherDeal.DealFees.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.FeeAmount, 0)
      }

      let amount = incomingFund.Amount - fee;

      return <>
        <div className="col-xs-2">
          {formatToCurrency(incomingFund.Amount + VATIncomingFunds, anotherDeal.BuyCurrency)} {anotherDeal.BuyCurrency}
        </div>
        <div className="col-xs-1°5">
          {formatToCurrency(fee.toString(), anotherDeal.BuyCurrency)} {anotherDeal.BuyCurrency}
        </div>
        <div className="col-xs-1°5">
          {formatToCurrency(VATIncomingFunds.toString(), anotherDeal.BuyCurrency)} {anotherDeal.BuyCurrency}
        </div>
        <div className="col-xs-2">
          {formatToCurrency(amount.toString(), anotherDeal.BuyCurrency)} {anotherDeal.BuyCurrency}
        </div>
        <div className="col-xs-2">{incomingFund.SettlementDeliveryMethod}</div>
        <div className="col-xs-2">{incomingFund.SettlementInstrument}</div>
        <div className="col-xs-1">{incomingFund.Status}</div>
      </>
    }

    return <>
      <div className="col-xs-2">
        {formatToCurrency(incomingFund.Amount + VATIncomingFunds, anotherDeal.BuyCurrency)} {anotherDeal.BuyCurrency}
      </div>
      <div className="col-xs-4">{incomingFund.SettlementDeliveryMethod}</div>
      <div className="col-xs-4">{incomingFund.SettlementInstrument}</div>
      <div className="col-xs-2">{incomingFund.Status}</div>
    </>
  }

  const GetIncomingColumns = () => {
    if (deal.VAT > -1) {
      return <>
        <div className="col-xs-2 bold-text">{getResource('AmountToPay')}</div>
        <div className="col-xs-1°5 bold-text">{getResource('Label.Fee')}</div>
        <div className="col-xs-1°5 bold-text">{`${getResource('Label.VAT')} (${deal.VATPercentage}%)`}</div>
        <div className="col-xs-2 bold-text">{getResource('Amount')}</div>
        <div className="col-xs-2 bold-text">{getResource('Label.DeliveryMethod')}</div>
        <div className="col-xs-2 bold-text">{getResource('Label.Instrument')}</div>
        <div className="col-xs-1 bold-text">{getResource('Label.Status')}</div>
      </>
    }
    else {
      return <>
        <div className="col-xs-2 bold-text">{getResource('AmountToPay')}</div>
        <div className="col-xs-4 bold-text">{getResource('Label.DeliveryMethod')}</div>
        <div className="col-xs-4 bold-text">{getResource('Label.Instrument')}</div>
        <div className="col-xs-2 bold-text">{getResource('Label.Status')}</div>
      </>
    }
  }

  let outgoingFunds: any[] = [];

  if (deal['Deal Type'] === 1) {
    if (deal.BeneficiaryPayments) {
      outgoingFunds = JSON.parse(deal.BeneficiaryPayments).map((outgoingFund: any) => {
        if (size[0] > 768) {
          return (
            <React.Fragment>
              <div className="row margin-left-detail-summary">
                <div className="col-xs-2">{outgoingFund.BeneficiaryName}</div>
                <div className="col-xs-2">{outgoingFund.CountryName}</div>
                <div className="col-xs-2">
                  {formatToCurrency(outgoingFund.Amount, outgoingFund.CurrencyID)} {outgoingFund.CurrencyID}
                </div>
                <div className="col-xs-2">{outgoingFund.Instrument}</div>
                <div className="col-xs-2">{outgoingFund.BankName} - {outgoingFund.AccountNo}</div>
                <div className="col-xs-2">{outgoingFund.Status}</div>
              </div>
            </React.Fragment>
          );
        }
        else {
          return (
            <React.Fragment>
              <div className="row margin-left-detail-summary">
                <div className="col-xs-4">{outgoingFund.BeneficiaryName}</div>
                <div className="col-xs-4">
                  {formatToCurrency(outgoingFund.Amount, outgoingFund.CurrencyID)} {outgoingFund.CurrencyID}
                </div>
                <div className="col-xs-4">{outgoingFund.Status}</div>
              </div>
            </React.Fragment>
          );
        }
      });
    } else {
      if (anotherDeal.OutgoingFunds) {
        outgoingFunds = anotherDeal.OutgoingFunds.map((outgoingFund: any) => {
          if (size[0] > 768) {
            return (
              <React.Fragment>
                <div className="row margin-left-detail-summary">
                  <div className="col-xs-2">{outgoingFund.Name}</div>
                  <div className="col-xs-2">{outgoingFund.CountryName}</div>
                  <div className="col-xs-2">
                    {formatToCurrency(outgoingFund.PaymentAmount, outgoingFund.SellCurrency)} {anotherDeal.SellCurrency}
                  </div>
                  <div className="col-xs-2">{outgoingFund.PaymentInstrument}</div>
                  <div className="col-xs-2"></div>
                  <div className="col-xs-2">{outgoingFund.Status}</div>
                </div>
              </React.Fragment>
            );
          }
          else {
            return (
              <React.Fragment>
                <div className="row margin-left-detail-summary">
                  <div className="col-xs-4">{outgoingFund.Name}</div>
                  <div className="col-xs-4">
                    {formatToCurrency(outgoingFund.PaymentAmount, outgoingFund.SellCurrency)} {anotherDeal.SellCurrency}
                  </div>
                  <div className="col-xs-4">{outgoingFund.Status}</div>
                </div>
              </React.Fragment>
            );
          }

        });
      }
    }
    if (size[0] > 768) {
      outgoingFunds.unshift(<div className="row margin-left-detail-summary">
        <div className="col-xs-2 bold-text">{getResource('Js_TH_Beneficiary')}</div>
        <div className="col-xs-2 bold-text">{getResource('Label.Country')}</div>
        <div className="col-xs-2 bold-text">{getResource('SendAmount')}</div>
        <div className="col-xs-2 bold-text">{getResource('Label.Instrument')}</div>
        <div className="col-xs-2 bold-text">{getResource('BankAccountInformation')}</div>
        <div className="col-xs-2 bold-text">{getResource('Label.Status')}</div>
      </div>);
    } else {
      outgoingFunds.unshift(<div className="row margin-left-detail-summary" >
        <div className="col-xs-5 bold-text">{getResource('Js_TH_Beneficiary')}</div>
        <div className="col-xs-3 bold-text">{getResource('SendAmount')}</div>
        <div className="col-xs-3 bold-text">{getResource('Label.Status')}</div>
      </div>);
    }
  } else if (deal['Deal Type'] === 4) {
    if (dealDrawdowns && dealDrawdowns.length > 0) {
      outgoingFunds = dealDrawdowns.map((outgoingFund: any) => {
        if (size[0] > 768) {
          return (
            <React.Fragment>
              <div className="row middle-xs margin-left-detail-summary">
                <div className="col-xs-1°5">{outgoingFund['Deal Header ID']}</div>
                <div className="col-xs-1°5">
                  {formatToCurrency(outgoingFund['Sell Amount'], outgoingFund['Sell Currency'])} {outgoingFund['Sell Currency']}
                </div>
                <div className="col-xs-1°5">
                  {formatToCurrency(outgoingFund['Buy Amount'], outgoingFund['Buy Currency'])} {outgoingFund['Buy Currency']}
                </div>
                <div className="col-xs-1°5">{outgoingFund['Fee']} {outgoingFund['Buy Currency']}</div>
                <div className="col-xs-1°5">{outgoingFund['Value Date']}</div>
                <div className="col-xs-1°5">{outgoingFund['Status']}</div>
                <div className="col-xs-3 row end-xs" style={{ margin: 0, padding: 0 }}>
                  {props.useConfirmationButton && (
                    <div className="col-md-12 col-xs-12">
                      <Button type="secondary" disabled={deal.Status !== 'Accepted'} onClick={() => onDownloadConfirmation(outgoingFund['Deal Header ID'])}>
                        {getResource('DownloadConfirmation')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <div className="row middle-xs margin-left-detail-summary" >
                <div className="col-xs-3">{outgoingFund['Deal Header ID']}</div>
                <div className="col-xs-3">
                  {formatToCurrency(outgoingFund['Sell Amount'], outgoingFund['Sell Currency'])} {outgoingFund['Sell Currency']}
                </div>
                <div className="col-xs-3">
                  {formatToCurrency(outgoingFund['Buy Amount'], outgoingFund['Buy Currency'])} {outgoingFund['Buy Currency']}
                </div>
                <div className="col-xs-3">{outgoingFund['Status']}</div>
              </div>
              <div className="col-xs-4 row end-xs" style={{ margin: 0, padding: 0 }}>
                {props.useConfirmationButton && (
                  <div className="col-md-12 col-xs-12">
                    <Button type="secondary" disabled={deal.Status !== 'Accepted'} onClick={() => onDownloadConfirmation(outgoingFund['Deal Header ID'])}>
                      {getResource('DownloadConfirmation')}
                    </Button>
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        }
      });
    } else if (deal.BeneficiaryPayments) {
      outgoingFunds = JSON.parse(deal.BeneficiaryPayments).map((outgoingFund: any) => {
        if (size[0] > 768) {
          return (
            <React.Fragment>
              <div className="row margin-left-detail-summary">
                <div className="col-xs-2">{outgoingFund.BeneficiaryName}</div>
                <div className="col-xs-1">{outgoingFund.CountryName}</div>
                <div className="col-xs-2">
                  {formatToCurrency(outgoingFund.Amount, outgoingFund.CurrencyID)} {outgoingFund.CurrencyID}
                </div>
                <div className="col-xs-2">{outgoingFund.Instrument}</div>
                <div className="col-xs-4">{outgoingFund.BankName} - {outgoingFund.AccountNo}</div>
                <div className="col-xs-1">{outgoingFund.Status}</div>
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <div className="row margin-left-detail-summary">
                <div className="col-xs-2">{outgoingFund.BeneficiaryName}</div>
                <div className="col-xs-2">
                  {formatToCurrency(outgoingFund.Amount, outgoingFund.CurrencyID)} {outgoingFund.CurrencyID}
                </div>
                <div className="col-xs-1">{outgoingFund.Status}</div>
              </div>
            </React.Fragment>
          );
        }
      });
    } else {
      if (anotherDeal.OutgoingFunds) {
        outgoingFunds = anotherDeal.OutgoingFunds.map((outgoingFund: any) => {
          if (size[0] > 768) {
            return (
              <React.Fragment>
                <div className="row margin-left-detail-summary">
                  <div className="col-xs-2">{outgoingFund.Name}</div>
                  <div className="col-xs-2">{outgoingFund.CountryName}</div>
                  <div className="col-xs-2">
                    {formatToCurrency(outgoingFund.PaymentAmount, outgoingFund.SellCurrency)} {anotherDeal.SellCurrency}
                  </div>
                  <div className="col-xs-2">{outgoingFund.PaymentInstrument}</div>
                  <div className="col-xs-2"></div>
                  <div className="col-xs-2">{outgoingFund.Status}</div>
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <div className="row margin-left-detail-summary">
                  <div className="col-xs-2">{outgoingFund.Name}</div>
                  <div className="col-xs-2">
                    {formatToCurrency(outgoingFund.PaymentAmount, outgoingFund.SellCurrency)} {anotherDeal.SellCurrency}
                  </div>
                  <div className="col-xs-2">{outgoingFund.Status}</div>
                </div>
              </React.Fragment>
            );
          }
        });
      }
    }
    if (dealDrawdowns && dealDrawdowns.length > 0) {
      if (size[0] > 768) {
        outgoingFunds.unshift(<div className="row margin-left-detail-summary">
          <div className="col-xs-1°5 bold-text">{getResource('Table_TH_DealNumber')}</div>
          <div className="col-xs-1°5 bold-text">{getResource('Table_TH_SellAmount')}</div>
          <div className="col-xs-1°5 bold-text">{getResource('Table_TH_BuyAmount')}</div>
          <div className="col-xs-1°5 bold-text">{getResource('Label.Fee')}</div>
          <div className="col-xs-1°5 bold-text">{getResource('Table_TH_ValueDate')}</div>
          <div className="col-xs-1°5 bold-text">{getResource('Label.Status')}</div>
        </div>);
      } else {
        outgoingFunds.unshift(<div className="row margin-left-detail-summary">
          <div className="col-xs-3 bold-text">{getResource('Table_TH_DealNumber')}</div>
          <div className="col-xs-3 bold-text">{getResource('Table_TH_SellAmount')}</div>
          <div className="col-xs-3 bold-text">{getResource('Table_TH_BuyAmount')}</div>
          <div className="col-xs-3 bold-text">{getResource('Label.Status')}</div>
        </div>);
      }
    }
    else {
      if (size[0] > 768) {
        outgoingFunds.unshift(<div className="row margin-left-detail-summary">
          <div className="col-xs-2 bold-text">{getResource('Js_TH_Beneficiary')}</div>
          <div className="col-xs-1 bold-text">{getResource('Label.Country')}</div>
          <div className="col-xs-2 bold-text">{getResource('SendAmount')}</div>
          <div className="col-xs-2 bold-text">{getResource('Label.Instrument')}</div>
          <div className="col-xs-4 bold-text">{getResource('BankAccountInformation')}</div>
          <div className="col-xs-1 bold-text">{getResource('Label.Status')}</div>
        </div>);
      } else {
        outgoingFunds.unshift(<div className="row margin-left-detail-summary">
          <div className="col-xs-5 bold-text">{getResource('Js_TH_Beneficiary')}</div>
          <div className="col-xs-3 bold-text">{getResource('SendAmount')}</div>
          <div className="col-xs-3 bold-text">{getResource('Label.Status')}</div>
        </div>);
      }
    }
  }

  let incomingFunds: any[] = [];
  if (anotherDeal.IncomingFunds) {
    if (size[0] > 768) {
      incomingFunds = anotherDeal.IncomingFunds.map((incomingFund: any) => {
        return (
          <div className="row margin-left-detail-summary">
            {GetIncomingData(incomingFund)}            
          </div>
        );
      });
    } else {
      incomingFunds = anotherDeal.IncomingFunds.map((incomingFund: any) => {
        return (
          <div className="row margin-left-detail-summary">
            <div className="col-xs-3">
              {formatToCurrency(incomingFund.Amount + VATIncomingFunds, anotherDeal.BuyCurrency)} {anotherDeal.BuyCurrency}
            </div>
            <div className="col-xs-6">{incomingFund.SettlementInstrument}</div>
            <div className="col-xs-3">{incomingFund.Status}</div>
          </div>
        );
      });
    }

    if (size[0] > 768) {
      incomingFunds.unshift(<div className="row margin-left-detail-summary">
        {GetIncomingColumns()}
      </div>)
    } else {
      incomingFunds.unshift(<div className="row margin-left-detail-summary">
        <div className="col-xs-3 bold-text">{getResource('AmountToPay')}</div>
        <div className="col-xs-6 bold-text">{getResource('Label.Instrument')}</div>
        <div className="col-xs-3 bold-text">{getResource('Label.Status')}</div>
      </div>)
    }
  }

  const getDealTypeName = (dealType: number) => {
    switch (dealType) {
      case 1:
        return 'Spot';
      case 4:
        return 'Forward';
      case 2:
        return 'Drawdown';
      default:
        return 'Spot';
    }
  };

  const onNewTradeClick = () => {
    if (props.onClickNewTrade) {
      props.onClickNewTrade();
    }
  };

  const onClickExit = () => {
    if (props.onClickExit) {
      props.onClickExit();
    }
  };

  const handleChangeComment = (event: any) => {
    setComments(event.target.value);
  }

  const onClickSaveComment = () => {
    let objComments = {
      DealHeaderId: deal['Deal Header ID'],
      InternalComment: null,
      ExternalComment: comments,
      CustomerRefNo: null,
      UpdateToken: updateToken
    }

    saveDealComments(objComments).then((response) => {
      if (response.httpStatusCode === 200) {
        setUpdateToken(response.updateToken);
        showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfully'));

      } else {
        showModal(getResource('Js_MessageError_TitleError'), getResource('Message_Unexpected'));
      }
    });
  };

  const onDownloadConfirmation = async (dealID: number) => {
    showLoadingModal();
    await getConfirmation(dealID).then((value) => {      
      const httpStatusCode = value.httpStatusCode;
      switch (httpStatusCode) {
        case 200:
          if (value != null && value.data != null) {
            let fileName = 'Deal Confirmation ' + dealID + '.pdf';
            var byteCharacters = atob(value.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var bytes = new Uint8Array(byteNumbers);
            var blob = new Blob([bytes], {
              type: mime.lookup(fileName) as string
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
          }
          break;

        case 500:
        case 503:
          showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + value.Message);
          break;
        default:
          showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
          break;
      }
      hideLoadingModal();
    });
  };

  return (
    <React.Fragment>
      {' '}
      {deal['Deal Header ID'] && (
        <div className="screen-container">

          <div className="row start-xs middle-xs">
            <div className="col-xs-12 subtitle-text-primary">{`${getResource('Title.DealInformation')} - ${deal['Deal Header ID']}`}</div>


          </div>

          <div className='row margin-left-detail-summary'>
            <div className='col-md-6 col-xs-12'>
              <div className="row start-xs">
                <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_DealType')}</div>
                <div className="col-xs-6 col-md-4" >{getDealTypeName(deal['Deal Type'])}</div>
              </div>
              <div className="row start-xs">
                <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_BuyAmount')}</div>
                <div className="col-xs-6 col-md-4">
                  {formatToCurrency(deal['Sell Amount'], deal['Sell Currency'])} {deal['Sell Currency']}
                </div>
              </div>
              <div className="row start-xs">
                <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_SellAmount')}</div>
                <div className="col-xs-6 col-md-4">
                  {formatToCurrency(deal['Buy Amount'], deal['Buy Currency'])} {deal['Buy Currency']}

                </div>
              </div>
              {(deal['Deal Type'] === 1 || (deal['Deal Type'] === 4 && deal['Forward Type'] === 'Closed')) && (
                <div className="row start-xs">
                  <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_FeeAmount')}</div>
                  <div className="col-xs-6 col-md-4">
                    {anotherDeal.DealFees != null ?
                      formatToCurrency(anotherDeal.DealFees.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.FeeAmount, 0), deal['Buy Currency']) : '0.00'} {deal['Buy Currency']}
                  </div>
                </div>
              )}
              {(deal.VAT > -1 && (deal['Deal Type'] === 1 || (deal['Deal Type'] === 4 && deal['Forward Type'] === 'Closed'))) && (
                <div className="row start-xs">
                  <div className="col-xs-6 col-md-4 bold-text">{getResource('Label.VAT')}</div>
                  <div className="col-xs-6 col-md-4">
                    {deal.VAT != null ?
                      formatToCurrency(deal.VAT, deal['Buy Currency']) : '0.00'} {deal['Buy Currency']}
                  </div>
                </div>
              )}
              {deal['Deal Type'] === 4 && anotherDeal.Deposits != null && (
                <div className="row start-xs">
                  <div className="col-xs-6 col-md-4 bold-text">{getResource('Form.Label.DepositRequired')} ({deal['Deposit Percent']}%)</div>
                  <div className="col-xs-6 col-md-4">
                    {formatToCurrency(anotherDeal.Deposits[0].Amount, deal['Buy Currency'])} {deal['Buy Currency']}
                  </div>
                </div>
              )}
              <div className="row start-xs">
                <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_Rate')}</div>
                <div className="col-xs-6 col-md-4">{deal['Customer Rate']}</div>
              </div>
              {deal['Deal Type'] === 1 && (
                <div className="row start-xs">
                  <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_ValueDate')}</div>
                  <div className="col-xs-6 col-md-4">{deal['Value Date']}</div>
                </div>
              )}
              {deal['Deal Type'] === 4 && (
                <div className="row start-xs">
                  <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_NearDate')}</div>
                  <div className="col-xs-6 col-md-4">{deal['Value Date']}</div>
                </div>
              )}
              {deal['Deal Type'] === 4 && (
                <div className="row start-xs">
                  <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_FarDate')}</div>
                  <div className="col-xs-6 col-md-4">{deal['Maturity Date']}</div>
                </div>
              )}
              <div className="row start-xs">
                <div className="col-xs-6 col-md-4 bold-text">{getResource('Table_TH_Status')}</div>
                <div className="col-xs-6 col-md-4">{deal['Status']}</div>
              </div>
              {deal['Deal Type'] === 4 && (
                <div className="row start-xs">
                  <div className="col-xs-6 col-md-4 bold-text">{getResource('Form.Label.ForwardType')}</div>
                  <div className="col-xs-6 col-md-4">{deal['Forward Type']}</div>
                </div>
              )}
              <div className="row start-xs">
                <div className="col-xs-6 col-md-4 bold-text">{getResource('PaidStatus')}</div>
                <div className="col-xs-6 col-md-4">{deal['Deal Paid Status']}</div>
              </div>
              {deal.Status === 'Rejected' && <div className="row start-xs">
                <div className="col-xs-6 col-md-4 bold-text">{getResource('Label.RejectReason')}</div>
                <div className="col-xs-6 col-md-4">{deal.RejectReason}</div>
              </div>}

            </div>

            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="bold-text">{getResource('Label.Comments')}</span>
              </div>
              <div className="row">
                <TextArea rows={4} value={comments} onChange={handleChangeComment} />
              </div>
              <div className="col-md-6 col-xs-12">
                <Button type="secondary" onClick={onClickSaveComment}>
                  {getResource('Button.SaveComments')}
                </Button>
              </div>
            </div>
          </div>


          {/*<Divider />*/}
          {incomingFunds !== undefined && incomingFunds.length > 0 && (
            <div className="row start-xs">
              <div className="col-xs-12 subtitle-text-primary">{getResource('Title.SettlementInformation')}</div>
            </div>
          )}
          {incomingFunds}
          {/*<Divider />*/}
          {outgoingFunds.length > 0 && (
            <>
              <div className="row start-xs">
                {(deal['Deal Type'] === 1 || (deal['Deal Type'] === 4 && anotherDeal.OutgoingFunds !== undefined && dealDrawdowns.length === 0)) && (
                  <div className="col-xs-12 subtitle-text-primary">{getResource('DisbursementsInformation')}</div>
                )}
                {deal['Deal Type'] === 4 && dealDrawdowns.length > 0 && <div className="col-xs-12 subtitle-text-primary">{getResource('DrawdownsInformation')}</div>}
              </div>
              {outgoingFunds}
            </>
          )}
          {props.useSaveTemplateButton && (
            <div className="row end-xs" style={{ paddingTop: 30 }}>
              <div className="col-md-3 row end-xs" style={{ margin: 0, padding: 0 }}>
                <Button
                  type="secondary"
                  onClick={() => {
                    setShowAddLikeTemplateModal(true);
                  }}
                >
                  {props.saveTemplateModalProps ? props.saveTemplateModalProps.ButtonLabel : getResource('AddDealTemplate')}
                </Button>
              </div>
            </div>
          )}
          <div className="row-actions" style={{ marginTop: '20px', marginBottom: '20px', padding: 0 }}>
            {props.showExitButton && (
              <Button type="secondary" onClick={onClickExit}>
                {getResource('Btn_Exit')}
              </Button>
            )}
            {props.useNewTradeButton && (
                <Button type="primary" onClick={onNewTradeClick}>
                  {getResource('NewTrade')}
              </Button>
            )}
            {props.useConfirmationButton && (
                <Button type="primary" disabled={deal.Status !== 'Accepted'} onClick={() => onDownloadConfirmation(props.dealHeaderID)}>
                  {getResource('DownloadConfirmation')}
              </Button>
            )}


          </div>
        </div>
      )}
      <AddEditOrderTemplate
        type={'add'}
        deal={deal}
        anotherDeal={anotherDeal}
        //outgoingFunds={outgoingFunds}
        show={showAddLikeTemplateModal}
        saveTemplateModalProps={{
          ButtonLabel: getResource('Button.AddOrderTemplate'),
          Modaltitle: getResource('Button.NewOrder')
        }}
        onCancel={() => setShowAddLikeTemplateModal(false)}
      />
    </React.Fragment>
  );
};

export default Summary;