import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Modal } from 'antd';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import CurrencyFlag from 'react-currency-flags';
import AppContext from '../../../App.context';
import { getCurrenciesByCustomer } from '../../../api/NetworkingCurrencies';
import { deleteARateOnBoard, rateBoard, saveRateBoard } from '../../../api/NetworkingWidget';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import Dropdown from '../../inputs/Dropdown';
interface RateBoardProps {
    width: string,
    height: string,
    onRowRateClick: (obj: any) => void,
}

const RateBoard: FunctionComponent<RateBoardProps> = (props: any) => {
    const { showModal } = useContext(AppContext);
    const [updating, setUpdating] = useState(false);

    const [listOfRates, setListOfRates] = useState([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    //Add new rate
    const [buyCurrencyList, setBuyCurrencyList] = useState([]);
    const [sellCurrencyList, setSellCurrencyList] = useState([]);
    const [sellSelected, setSellSelected] = useState({} as any);
    const [buySelected, setBuySelected] = useState({} as any);
    //validate add new rate
    const [validationBuy, setValidationBuy] = useState(true);
    const [validationSell, setValidationSell] = useState(true);
    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    //Delete Alert
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [currentRate, setCurrentRate] = useState({} as any);

    const [rows, setRows] = useState([]);

    useEffect(() => {
        getListRateBoard();
    }, [updating])

    useEffect(() => {
        rowsReturn();
    }, [listOfRates])

    const formatOptionLabel = (CurrencyID: string, CurrencyIDName: string, Type: string, NumberOfDecimals: number, BaseCurrencyDirectRate: string) => (
        /*
            "CurrencyID":"CAD",
            "CurrencyIDName":"CAD - CANADIAN DOLLAR",
            "Type":"Payment",
            "NumberOfDecimals":2,
            "BaseCurrencyDirectRate":0.740905
        */
        <div style={{ display: "flex" }}>
            <div>
                <CurrencyFlag
                    currency={CurrencyID}
                    width={18}
                ></CurrencyFlag>
            </div>
            <div style={{ marginLeft: "10px", color: "#00548d" }}>
                {CurrencyIDName.toUpperCase()}
            </div>
        </div>
    );

    const getCurrencies = () => {
        const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
        getCurrenciesByCustomer(customerID).then(
            (response) => {
                if (response !== undefined) {
                    console.log("getCurrenciesByCustomer: " + JSON.stringify(response));
                    console.log(response);
                    if (response.currencies !== undefined) {
                        var allCurrencies = response.currencies;
                        allCurrencies.forEach((element: any) => {
                            element.value = element.CurrencyID;
                            element.label = element.CurrencyIDName;
                        });
                        var buyList = allCurrencies.filter((currency: any) => currency.Type === "Payment");
                        var sellList = allCurrencies.filter((currency: any) => currency.Type === "Settlement");
                        setBuyCurrencyList(buyList);
                        setSellCurrencyList(sellList);
                    }
                }
            }
        );
    }
    const onClickAdd = () => {
        getCurrencies();
        setShowAddDialog(true);
    }

    const closeAddDialog = () => {
        setShowAddDialog(false);
        setBuySelected({});
        setSellSelected({});
        setValidationBuy(true);
        setValidationSell(true);
    }
    const handleChangeSelectSellSelect = (sellSelected: string) => {
        setSellSelected(sellSelected);
    };

    const handleChangeSelectBuySelect = (buySelected: any) => {
        setBuySelected(buySelected);
    };

    const onsaveRateBoard = () => {
        const personID = JSON.parse(localStorage.getItem('UserSession')!).PersonID;
        if (validation()) {
            saveRateBoard(buySelected.CurrencyID, sellSelected.CurrencyID, personID).then(
                (response) => {
                    if (response !== undefined) {
                        console.log("saveRateBoard: " + JSON.stringify(response));
                        console.log(response);
                        /*
                            saveRateBoard: {
                                "Message"       : "Cannot insert the value NULL into column 'BuyCurrency', table 'INTForeignExchange.dbo.RateBoardDef'; column does not allow nulls. INSERT fails.\r\nThe statement has been terminated.",
                                "ErrorMessage"  : null,
                                "httpStatusCode": 503
                            }
                        */
                        var messageText = '';
                        var titleText = '';
                        switch (response.httpStatusCode) {
                            case 503:
                                {
                                    titleText = getResource('Js_MessageError_TitleSubmit')
                                    messageText = response.Message
                                    break;
                                }
                            case 200:
                                {
                                    titleText = getResource('Js_MessageError_TitleSuccess');
                                    messageText = getResource('Js_Message_SaveSuccessfullyRate')
                                    break;
                                }
                            default:
                                {
                                    break;
                                }
                        }
                        setShowAddDialog(false);
                        showModal(titleText, messageText);
                        closeSavedRateBorard();
                        /*
                        setShowAlert(true);
                        setAlertTitle(titleText);
                        setAlertMessage(messageText);*/
                    }
                }
            );
        } else {
            showModal(getResource('Js_MessageError_TitleSubmit'), getResource('Js_MessageError_FormValidationNField'));
           /*
            setShowAlert(true);
            setAlertTitle(getResource('Js_MessageError_TitleSubmit'));
            setAlertMessage(getResource('Js_MessageError_FormValidationNField'));
            */
        }
    }

    const closeAlertError = () => {
        setShowAddDialog(true);
        setShowAlert(true);
        setAlertTitle('');
        setAlertMessage('');
    }

    const closeSavedRateBorard = () => {
        setShowAddDialog(false);
        //setShowAlert(false);
        //setAlertTitle('');
        //setAlertMessage('');
        setBuySelected('');
        setSellSelected('');
        setValidationBuy(true);
        setValidationSell(true);
        getListRateBoard();
    }

    const validation = () => {
        var result = true;
        var validationB = true;
        var validationS = true;
        var listErrors = [];

        if (buySelected === undefined || buySelected === null || buySelected === '') {
            result = false;
            validationB = false;
            listErrors.push('Buy Currency.');
        }

        if (sellSelected === undefined || sellSelected === null || sellSelected === '') {
            result = false;
            validationS = false;
            listErrors.push('Sell Currency.');
        }
        setValidationBuy(validationB);
        setValidationSell(validationS);

        return result
    }

    const getListRateBoard = () => {
        const personID = JSON.parse(localStorage.getItem('UserSession')!).PersonID;
        rateBoard(personID).then(
            (response) => {
                if (response !== undefined) {
                    console.log("rateBoard: " + JSON.stringify(response));
                    console.log(response);
                    setListOfRates(response.RateBoard);
                }
            }
        );
    }

    const deleteRate = (rate: any) => {
        console.log("deleteRate: " + JSON.stringify(rate));
        //deleteRate: {"BuyCurrency":"GBP","SellCurrency":"CAD","Rate":1.606102509}
        setCurrentRate(rate);
        setShowDeleteAlert(true);
    }

    const yesDeleteAlert = () => {
        if (currentRate !== null) {
            const personID = JSON.parse(localStorage.getItem('UserSession')!).PersonID;
            deleteARateOnBoard(currentRate.BuyCurrency, currentRate.SellCurrency, personID).then(
                (response) => {
                    if (response !== undefined) {
                        console.log("rateBoard: " + JSON.stringify(response));
                        console.log(response);
                        /*
                            rateBoard: 
                            {
                                "Message"       :"The rate has been deleted successfully.",
                                "ErrorMessage"  :null,
                                "httpStatusCode":200
                            }
                        */
                        switch (response.httpStatusCode) {
                            case 200:
                                {
                                    setShowDeleteAlert(false);
                                    setCurrentRate(null);
                                    getListRateBoard();
                                    break;
                                }
                            default:
                                break;
                        }
                    }
                }
            );
        }
    }

    const closeDeleteAlert = () => {
        setShowDeleteAlert(false);
        setCurrentRate(null);
    }

    const onClickRowRate = (rate: any) => {
        console.log("onClickRowRate: " + JSON.stringify(rate));
        /*
            onClickRowRate: {"BuyCurrency":"AUD","SellCurrency":"CAD","Rate":0.80579}
        */
        if (props.onRowRateClick) {
            rate.DealType = 'Order';
            rate['Payment Amount'] = 0;
            rate['Settlement CurrencyID'] = rate.BuyCurrency;
            rate['Payment Currency ID'] = rate.SellCurrency;
            props.onRowRateClick(rate);
        }
    }

    const rowsReturn = () => {
        var rowsHelper: any = [];
        if (listOfRates && listOfRates.length !== 0) {
            listOfRates.forEach((element: any, index: number) => {
                rowsHelper.push(
                    <tr
                        className={"cursorPointer ant-table-row ant-table-row-level-" + index}
                        style={{
                            //borderRadius: '10px',
                            //border: '2px solid #E7E7E7',
                            textAlign: 'center',
                            borderBottom: '2px solid #E7E7E7',
                            margin: '0px', padding: '0px'
                        }}
                    >
                        <td className='ant-table-cell' style={{ margin: '0px', padding: '0px' }} onClick={onClickRowRate.bind(this, element)}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', textAlign: 'right', fontSize: 14 }}>
                                    <span>{element.BuyCurrency}</span>
                                </div>
                                <div style={{ width: '50%', textAlign: 'left', paddingLeft: '5px' }}>
                                    <CurrencyFlag currency={element.BuyCurrency} width={16} />
                                </div>
                            </div>
                        </td>
                        <td className='ant-table-cell' style={{ margin: '0px', padding: '0px' }} onClick={onClickRowRate.bind(this, element)}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', textAlign: 'right', fontSize: 14 }}>
                                    <span >{element.SellCurrency}</span>
                                </div>
                                <div style={{ width: '50%', textAlign: 'left', paddingLeft: '5px' }}>
                                    <CurrencyFlag currency={element.SellCurrency} width={16} />
                                </div>
                            </div>
                        </td>
                        <td className='ant-table-cell'
                            style={{ fontSize: 14, margin: '0px', padding: '0px' }}
                            onClick={onClickRowRate.bind(this, element)}
                        >{element.Rate}</td>
                        <td className='ant-table-cell' style={{ margin: '0px', padding: '0px' }} >
                            <DeleteOutlined className='icon-delete-primary-color' onClick={() => { deleteRate(element) }} />
                        </td>
                    </tr>
                )
            });
        }
        setRows(rowsHelper);
    }

    return (
        <div
            style={{
                width: props.width,
                height: props.height,
                //overflow: 'auto',
                //backgroundColor: 'yellow',
                flexDirection: 'column',
                padding: '10px 5px',
                //
                display: 'flex',
                alignItems: 'stretch',
                margin: '0 auto',
                //borderRadius: '10px',
                //border: '2px solid #E7E7E7',
            }}
        >
            <div className="breadcrumb-container">
                <Breadcrumb separator="">
                    <Breadcrumb.Item >{'Rate Board'}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div
                className="table-fixed-header-responsive"
                style={{
                    width: '100%',
                    height: '100%',
                    margin: 0,
                    paddingTop: 2
                }}
            >
                <table className={''} style={{ margin: '0px', padding: '0px' }}>
                    <thead className={'ant-table-thead'} >
                        <tr>
                            <th className='ant-table-cell no-border' style={{ width: '35%', margin: '0px', padding: '0px 16px' }}>{getResource('Label.BuyUpper')}</th>
                            <th className='ant-table-cell no-border' style={{ width: '35%', margin: '0px', padding: '0px 16px' }}>{getResource('Label.SellUpper')}</th>
                            <th className='ant-table-cell no-border' style={{ width: '20%', margin: '0px', padding: '0px 16px' }}>{getResource('Label.Rate')}</th>
                            <th className='ant-table-cell no-border' style={{ width: '10%', margin: '0px', padding: '0px 16px' }}>
                                <PlusCircleOutlined className='icon-white-color' onClick={onClickAdd} />
                            </th>
                        </tr>
                    </thead>
                    <tbody className={'ant-table-tbody'} >
                        {rows}
                    </tbody>
                </table>
            </div>
            {showAddDialog ? <Modal
                title={
                    <div className="breadcrumb-container">
                        <Breadcrumb separator="">
                            <Breadcrumb.Item >{getResource('AddRateBoard')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                }
                centered
                visible={showAddDialog}
                footer={null}
                onCancel={closeAddDialog}
                maskClosable={false}
                //width={'25%'}
                zIndex={1000}
                closable={false}
            >
                <div style={{ overflowY: 'visible', padding: 10 }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <span
                            style={{
                                width: '30%',
                                textAlign: 'center',
                                margin: 'auto'
                            }}
                        >{getResource('Form.Label.BuyCurrency')}</span>
                        <div
                            style={{
                                width: '70%',
                                margin: 'auto'
                            }}
                        >
                            <Dropdown
                                name="request-buyCurrency"
                                title={getResource('SelectCurrencyBuy')}
                                list={buyCurrencyList}
                                select={{ value: buySelected }}
                                onChange={handleChangeSelectBuySelect}
                                style={{ border: '1px solid red' }}
                            ></Dropdown>
                            {/* <Select
                                style={{ border: '1px solid red' }}
                                value={buySelected}
                                onChange={handleChangeSelectBuySelect}
                                formatOptionLabel={formatOptionLabel}
                                options={buyCurrencyList}
                                styles={{
                                    control: (base: any, state: any) => ({
                                        ...base,
                                        background: "white",
                                        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
                                        borderColor: state.isFocused ? '#cccccc' : (validationBuy ? '#cccccc' : 'red'),
                                        boxShadow: state.isFocused ? null : null,
                                        "&:hover": {
                                            //borderColor: state.isFocused ? "#cccccc" : "#cccccc"
                                        }
                                    }),
                                    dropdownIndicator: (base: any, state: any) => ({
                                        ...base,
                                        cursor: 'pointer'
                                    }),
                                    option: (base: any, state: any) => ({
                                        ...base,
                                        cursor: 'pointer',
                                        background: state.isSelected ? '#8ac43f' : "white",
                                        boxShadow: state.isFocused ? null : null,
                                        "&:hover": {
                                            background: state.isFocused ? "#d0edab" : "white"
                                        },
                                    }),
                                    valueContainer: (base: any, state: any) => ({
                                        ...base,
                                        cursor: 'text',
                                    })
                                }}
                            /> */}
                        </div>
                    </div>
                    <div
                        style={{
                            paddingTop: 10,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <span
                            style={{
                                width: '30%',
                                textAlign: 'center',
                                margin: 'auto'
                            }}
                        >{getResource('Form.Label.SellCurrency')}</span>
                        <div
                            style={{
                                width: '70%',
                                margin: 'auto'
                            }}
                        >
                            <Dropdown
                                name="request-sellCurrency"
                                title={getResource('SelectCurrencySell')}
                                list={sellCurrencyList}
                                select={{ value: sellSelected }}
                                onChange={handleChangeSelectSellSelect}
                                style={{ border: '1px solid red' }}
                            ></Dropdown>
                            {/* <Select
                                value={sellSelected}
                                onChange={handleChangeSelectSellSelect}
                                //defaultValue        = {options[0]}
                                formatOptionLabel={formatOptionLabel}
                                options={sellCurrencyList}
                                styles={{
                                    control: (base: any, state: any) => ({
                                        ...base,
                                        background: 'white',
                                        // match with the menu
                                        borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
                                        // Overwrittes the different states of border
                                        borderColor: state.isFocused ? '#cccccc' : (validationSell ? '#cccccc' : 'red'),
                                        // Removes weird border around container
                                        boxShadow: state.isFocused ? null : null,
                                        '&:hover': {
                                            // Overwrittes the different states of border
                                            //: state.isFocused ? '#cccccc' : '#cccccc'
                                        }
                                    }),
                                    dropdownIndicator: (base: any, state: any) => ({
                                        ...base,
                                        cursor: 'pointer'
                                    }),
                                    option: (base: any, state: any) => ({
                                        ...base,
                                        //alignVertical: 'center',
                                        //height: '50px',
                                        //paddingTop : 2,
                                        //paddingLeft: 8,
                                        cursor: 'pointer',
                                        background: state.isSelected ? '#8ac43f' : "white",
                                        boxShadow: state.isFocused ? null : null,
                                        "&:hover": {
                                            // Overwrittes the different states of border
                                            background: state.isFocused ? "#d0edab" : "white"
                                        },
                                    }),
                                    valueContainer: (base: any, state: any) => ({
                                        ...base,
                                        cursor: 'text',
                                    })
                                }}
                            /> */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="row-actions">
                            <Button id="btn-f-addRateBoard-save" type="primary" onClick={onsaveRateBoard}>
                                {getResource('Button.Save')}
                            </Button>
                            <Button id="btn-f-addRateBoard-close" type="primary" onClick={closeAddDialog}>
                                {getResource('Btn_Exit')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal> : null}
            {/* <Modal
                title={
                    <div className="breadcrumb-container">
                        <Breadcrumb separator="">
                            <Breadcrumb.Item >{alertTitle}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                }
                centered
                visible={showAlert}
                footer={null}
                onCancel={(alertTitle === getResource('Js_MessageError_TitleSubmit') || alertTitle === getResource('Js_MessageError_TitleError')) ? (closeAlertError) : (closeSavedRateBorard)}
                maskClosable={false}
                //width={'25%'}
                zIndex={1010}
                closable={false}
            >
                <div className="row">
                    <div className="col-md-9">{alertMessage}</div>
                    <div className="col-md-3">
                        <Button id="confirmation-OK" type="primary" onClick={(alertTitle === getResource('Js_MessageError_TitleSubmit') || alertTitle === getResource('Js_MessageError_TitleError')) ? (closeAlertError) : (closeSavedRateBorard)}>
                            {getResource('Button.Ok')}
                        </Button>
                    </div>
                </div>
            </Modal>
            */}

            <Modal
                title={
                    <div className="breadcrumb-container">
                        <Breadcrumb separator="">
                            <Breadcrumb.Item >{'Warning'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                }
                centered
                visible={showDeleteAlert}
                footer={null}
                onCancel={closeDeleteAlert}
                maskClosable={false}
                //width={'25%'}
                zIndex={1020}
                closable={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <span>
                            <span>{getResource('AreYouSureYouWantToDeleteRate')} </span>
                            {currentRate === null ? ('') : (
                                <span>
                                    {currentRate.BuyCurrency} <CurrencyFlag currency={currentRate.BuyCurrency} width={20} /> / {currentRate.SellCurrency} <CurrencyFlag currency={currentRate.SellCurrency} width={20} />?
                                </span>
                            )}
                        </span>
                    </div>
                    <div className="row">
                        <div className="row-actions">
                        <Button id="confirmation-YES" type="primary" onClick={yesDeleteAlert}>
                                {getResource('Yes')}
                            </Button>
                        <Button id="confirmation-NO" type="primary" onClick={closeDeleteAlert}>
                                {getResource('No')}
                        </Button>
                    </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default RateBoard;

//https://standaloneinstaller.com/blog/10-best-digital-fonts-144.html
//https://www.youtube.com/watch?v=Qk0y2Zwgam4&ab_channel=DevNami
//https://codesandbox.io/s/react-select-value-vs-menu-option-p5wf8?file=/src/index.js:198-205
//https://codesandbox.io/embed/reactselect-formatoptionlabel-bde1q