import { Modal, Steps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { getBankAccountsByAccountID } from '../../api/NetworkingBankAccount';
import { getOnlineBeneficiary, saveOnlineBeneficiary } from '../../api/NetworkingBeneficiaries';
import { getBranchInformationByBranchID } from '../../api/NetworkingBranch';
import useCountries from '../../api/hooks/useCountries';
import useCustomerCurrencies from '../../api/hooks/useCustomerCurrencies';
import { getResource } from '../../helpers/ResourcesHelper';
import ApprovalsNotification from '../approvals/ApprovalsNotification';
import Button from '../inputs/Button';
import { ICustomerCurrencies } from '../orders/currencyDetails/CurrencyDetails';
import BankAccountInfo from './BankAccountInfo';
import IntermediaryBankAccount from './IntermediaryBankAccount';

const BankAccountScreen = (props: any) => {

  const { showModal } = useContext(AppContext);
  const [showAFEXTC,setShowAFEXTC] = useState(false);
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [bankAccountFields, validationBankAccountFields] = useState(0);
  const [intermediaryBankAccountFields, validationIntermediaryBankAccountFields] = useState(0);
  const [sucessModal, setSucessModal] = useState(false);
  const [message, setMessage] = useState('');
  const [contactsAbleToApprove, setContactsAbleToApprove] =useState([
    {
      Email:'test@gmail.com',
      Name:'Test',
      label: 'Test',
      value:'test@gmail.com'
    }
  ]);
  const [currentStep, setCurrentStep] = useState(0);
  const { Step } = Steps;
  const [countries] = useCountries();
  const [currencies] = useCustomerCurrencies();
  const [bankAccount, setBankAccount] = useState({
    BankAccountID: 0,
    CountryID: '',
    BankName: '',
    BranchName: '',
    Address1: '',
    Address2: '',
    City: '',
    StateName: '',
    ZipCode: '',
    Institution: '',
    CurrencyID: '',
    AccountNo: '',
    BankCode: '',
    Swift: '',
    StateID:0,
    UpdateToken:''    
  });

  const [intermediaryBankAccount, setIntermediaryBankAccount] = useState({
      CountryID: '',
      BankName: '',
      TransitNo: '',
      AccountNo: '',
      ABA: '',
      Swift: ''
  });

  const [beneficiary, setBeneficiary] = useState({
    BeneficiaryID: 0,
    BeneficiaryType: '',
    BeneficiaryName: '',
    BeneficiaryFirstName: '',
    BeneficiaryLastName: '',
    BeneficiaryShortName: '',
    AddressLine1: '',
    AddressLine2: '',
    CustomerID: 0,
    City: '',
    CountryID: '',
    StateID: 0,
    StateName: '',
    Status: '',
    PostalCode: '',
    PreferredMethodOfPayment: '',
    PreferredDeliveryMethod: '',
    EmailAddress: '',
    PaymentLimit: 0,
    DetailsOfPayment: '',
    PaymentReference: '',
    PhoneNumber: '',
    PhoneExtension: '',
    FaxNumber: '',
    MobileNumber: '',
    ExternalBeneficiaryKey: '',
    UpdatedBy: '',
    UpdateToken: '',
    UpdatedByContactID: 0,
    BankAccounts: [
      {
        BankAccountID: 0,
        TransitNo: '', //BankCode
        BankName: '',
        BranchName: '',
        AddressLine1: '',
        AddressLine2: '',
        SWIFTID: '',
        ABA: '',
        AccountNo:  '',
        CurrencyID:'',
        City: '',
        CountryID: '',
        StateID: 0,
        PostalCode: '',
        IntermediaryCountryID: '',
        IntermediaryBankName: '',
        IntermediaryTransitNo: '',
        IntermediaryAccountNo: '',
        IntermediaryABA: '',
        IntermediarySWIFTID: '',
        UpdateToken:''
      }
    ]
  });

  const onUpdateIntermediaryBankAccount = (label: keyof typeof intermediaryBankAccount, value: any) => {
    let bA = intermediaryBankAccount;

    (bA[label] as any) = value;
    setIntermediaryBankAccount(bA);
  };

  const onUpdateBankAccount = (label: keyof typeof bankAccount, value: any) => {
    let bA = bankAccount;

    (bA[label] as any) = value;
    setBankAccount(bA);
  };

  const onUpdateStep = (step: number) => {
    if (step === 2) {
      saveAll();
    }
    else {
      setCurrentStep(step);
    };
  };

  useEffect(() => {
    if (props.bankAccountID !== undefined && props.bankAccountID > 0 && props.beneficiaryID === undefined)
    {
      loadBankAccountInfo(props.bankAccountID);
    }
    else
    {
      if(props.beneficiaryID != null && props.beneficiaryID > 0)
      {
          getOnlineBeneficiary(props.beneficiaryID)
          .then(response => {
            if (response !== null && response.beneficiary !== null && response.beneficiary.length > 0) {
              setBeneficiary(response.beneficiary[0]);
              if(response.beneficiary[0].BankAccounts != null && response.beneficiary[0].BankAccounts.length > 0)
              {
                if (props.bankAccountID !== undefined && props.bankAccountID > 0)
                {
                  //@ts-ignore
                  let bA = response.beneficiary[0].BankAccounts.find((x: number) => x.BankAccountID === props.bankAccountID);
                  if(bA != null)
                  {
                    let intermediateBankAccount = {
                      CountryID: bA.IntermediaryCountryID,
                      BankName: bA.IntermediaryBankName,
                      TransitNo: bA.IntermediaryTransitNo,
                      AccountNo: bA.IntermediaryAccountNo,
                      ABA: bA.IntermediaryABA,
                      Swift: bA.IntermediarySWIFTID
                    }  

                    let bankAccountAux: typeof bankAccount = {
                      BankAccountID : bA.BankAccountID,
                      CountryID : bA.CountryID,
                      BankName: bA.BankName,
                      BranchName: bA.BranchName,
                      Address1: bA.AddressLine1,
                      Address2: bA.AddressLine2,
                      City: bA.City,
                      StateName: bA.StateName,
                      StateID: bA.StateID != null ? bA.StateID : 0,
                      ZipCode: bA.PostalCode,
                      Institution: bA.ABA,
                      CurrencyID: bA.CurrencyID,
                      AccountNo: bA.AccountNo,
                      BankCode:  bA.TransitNo,
                      Swift:  bA.SWIFTID,
                      UpdateToken: bA.UpdateToken
                    }
                    setBankAccount(bankAccountAux);
                    setIntermediaryBankAccount(intermediateBankAccount);
                  }
                }
              }
            }
          });
      }      
    }
  }
, [props.bankAccountID,props.beneficiaryID]);

  const steps = [
    {
      title: getResource('Message.BankAccount'),
      content: <BankAccountInfo bankAccount={bankAccount} currencies={(currencies as ICustomerCurrencies).payment}
        countries={countries} canEdit={props.canEdit}
        onUpdateBankAccount={onUpdateBankAccount} bankAccountFields={bankAccountFields} onUpdateStep={onUpdateStep} />
    },
    {
      title: getResource('Message.IntermediaryBankAccount'),
      content: <IntermediaryBankAccount intermediary={intermediaryBankAccount} countries={countries} canEdit={props.canEdit}
        onUpdateIntermediaryBankAccount={onUpdateIntermediaryBankAccount} intermediaryBankAccountFields={intermediaryBankAccountFields}
        onUpdateStep={onUpdateStep} />
    }
  ];

  const loadBankAccountInfo = (bankAccountId:number) => {
    getBankAccountsByAccountID(bankAccountId).then(response => {
      if (response !== null && response.root !== null && response.httpStatusCode === 200) {
        let brachId = response.root[0]['Branch ID'];
        getBranchInformationByBranchID(brachId).then((branchResponse) => {
          let Address1 = '';
          let Address2 = '';
          let City = '';
          let StateName = '';
          let ZipCode = '';
          let StateID = 0;
          if (branchResponse !== null && branchResponse !== undefined && branchResponse.httpStatusCode === 200) {
            Address1 = branchResponse['Address Value'];
            Address2 = branchResponse['Address2'];
            City = branchResponse['City'];
            StateName = branchResponse['StateName'];
            StateID = branchResponse['StateID'];
            ZipCode = branchResponse['ZipCode'];
          }
          let intermediateBankAccount = {
            CountryID: '',
            BankName: '',
            TransitNo: '',
            AccountNo: '',
            ABA: '',
            Swift: ''
          };
          const jsonIntermediary = JSON.parse(response.root[0].Intermediate);
          let intermediary = jsonIntermediary !== null ? jsonIntermediary[0] : null;
          if (intermediary !== null) {
            intermediateBankAccount = {
              CountryID: intermediary['Country ID'],
              BankName: intermediary['Bank Name'],
              TransitNo: intermediary['Transit No'],
              AccountNo: intermediary['Account No'],
              ABA: intermediary['ABA No'],
              Swift: intermediary['SWIFT']
              //updateToken: intermediary.UpdateToken
            };
          }

          let bankAccountAux: typeof bankAccount = {
            BankAccountID: 0,
            CountryID: response.root[0]['CountryID'],
            BankName: response.root[0]['Bank Name'],
            BranchName: response.root[0]['Branch Name'],
            Address1: Address1,
            Address2: Address2,
            City: City,
            StateName: StateName,
            StateID: StateID,
            ZipCode: ZipCode,
            Institution: response.root[0]['ABA No'],
            CurrencyID: response.root[0]['Currency'],
            AccountNo: response.root[0]['Account No'],
            BankCode: response.root[0]['Transit No'],
            Swift: response.root[0]['SWIFT'],
            UpdateToken: ''
          };
          setBankAccount(bankAccountAux);
          setIntermediaryBankAccount(intermediateBankAccount);
        });
      }
    });
  }

  const onCancelClick = () =>
  {
    if (props.onCancelClick !== undefined)
    {
        props.onCancelClick();
    }
    setSucessModal(false)
  }

  const onNextClick = () => {
    switch (currentStep) {
      case 0:
        if (props.canEdit) {
          validationBankAccountFields(bankAccountFields + 1);
        }
        else { setCurrentStep(currentStep + 1) }
        break;
      case 1:
        if (props.canEdit) {
        validationIntermediaryBankAccountFields(intermediaryBankAccountFields + 1);
        }
        break;
      default: setCurrentStep(currentStep + 1); break;
    }
  };
  
  const onPreviousClick = () => {
    validationBankAccountFields(0);
    validationIntermediaryBankAccountFields(0);
    setCurrentStep(currentStep - 1);
  };

  const validateBankAccount= () =>{
    return true;

    /*  else {
        showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
        return false;
      }*/
  }

  const transformBankAccount=() =>{
    var bankAccountBene: typeof beneficiary.BankAccounts[0] = {
      BankAccountID: bankAccount.BankAccountID,
      TransitNo: bankAccount.BankCode,
      BankName: bankAccount.BankName,
      BranchName: bankAccount.BranchName,      
      SWIFTID: bankAccount.Swift,
      ABA: bankAccount.Institution,
      AccountNo: bankAccount.AccountNo,
      CurrencyID: bankAccount.CurrencyID,
      City: bankAccount.City,
      CountryID: bankAccount.CountryID,
      StateID: bankAccount.StateID != null ? bankAccount.StateID : 0,
      PostalCode: bankAccount.ZipCode,
      IntermediaryCountryID: intermediaryBankAccount.CountryID,
      IntermediaryBankName: intermediaryBankAccount.BankName,
      IntermediaryTransitNo: intermediaryBankAccount.TransitNo,
      IntermediaryAccountNo: intermediaryBankAccount.AccountNo,
      IntermediaryABA: intermediaryBankAccount.ABA,
      IntermediarySWIFTID: intermediaryBankAccount.Swift,
      AddressLine1: bankAccount.Address1,
      AddressLine2: bankAccount.Address2,
      UpdateToken: bankAccount.UpdateToken
    };

    /*  if(beneficiary.BankAccounts != null && beneficiary.BankAccounts.length > 0)
      {
        var accounts = beneficiary.BankAccounts.filter( (bA: any) => bA.BankAccountID != bankAccountBene.BankAccountID);
        accounts.push(bankAccountBene);

        let bene = beneficiary;
        bene.BankAccounts = accounts;
        setBeneficiary(bene);
      }
      else
      {*/
        let bene = beneficiary;
        bene.BankAccounts = [];
        bene.BankAccounts.push(bankAccountBene);
        setBeneficiary(bene);        
     // }
  }

  const onSaveClick = () => {
    validationIntermediaryBankAccountFields(intermediaryBankAccountFields + 1);
  }

  const saveAll = () => {
    if(validateBankAccount())
    {
      let afexSession = JSON.parse(localStorage.getItem('UserSession')!);
      if (afexSession.OnlineInterface != null && afexSession.OnlineInterface.includes('AFEX - Pass Thru')) {
          //-----------AFEX---------
          setShowAFEXTC(true);
      } else {
            saveBeneficiary();
          }
    }
  }

  const saveBeneficiary = () =>
  {
    var userSession = JSON.parse(localStorage.getItem('UserSession')!);
    beneficiary.UpdatedByContactID = userSession.ContactID;
    beneficiary.UpdatedBy= userSession.PersonID;
    beneficiary.CustomerID = userSession.CustomerID;
    beneficiary.Status = userSession.BeneApproveRequired ? getResource('Beneficiary.Status') : beneficiary.Status;
    transformBankAccount();
    
    saveOnlineBeneficiary(beneficiary).then(
      (response) => {
          if (response !== undefined && response !== null) {
              let responseStatus = response.httpStatusCode
              switch (responseStatus) {
                  case 200:
                      if (response.ErrorMessage !== '') {

                          let requiresApproval = response.requiresApproval

                          if (requiresApproval === true) {

                            let contactsAbleToApprove = (response.contactsAbleToApprove === null || response.contactsAbleToApprove === '') ? [] : response.contactsAbleToApprove

                              if (contactsAbleToApprove.length === 0) {
                                  showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_MissingApproval'));
                              }
                              else {
                                contactsAbleToApprove.push({ Email: 'All', Name: getResource('PlaceHolder.SelectMailAll') });
                                contactsAbleToApprove = contactsAbleToApprove.map((c: any) => ({ ...c, label: c.Name, value: c.Email }));

                                setContactsAbleToApprove(contactsAbleToApprove);
                                setRequiresApproval(requiresApproval);
                              }

                          } else {
                              let message = getResource('Js_Message_AprovedBeneficiary')
                              if (response.externalInterfaceMessage !== undefined && response.externalInterfaceMessage !== null
                                  && response.externalInterfaceMessage !== '') {
                                  let interfaceMessage = response.externalInterfaceMessage.split("\n").map(function (item: any, idx:any) {
                                      return (
                                          <span key={idx}>
                                              {item}
                                              <br />
                                          </span>
                                      )
                                  })
                                  message = <span> {message} <br /> {interfaceMessage}</span>
                              }
                            //showModal(getResource('Js_Message_SubmitSucessful'), message);
                            setMessage(message)
                            setSucessModal(true)
                          }
                      } else {
                        showModal(getResource('Js_MessageError_TitleSubmit'), response.ErrorMessage);
                      }
                      break;
                  case 409:
                      showModal(getResource('Js_MessageError_TitleFormValidation'), response.httpErrorMessage);
                      break;
                  case (responseStatus >= 401 && responseStatus < 500):
                      showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
                      break;
                  case (responseStatus >= 500):
                  showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + response.Message);
                      break;
                  default:
                  showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
                      break;
              }
            }
          }
    );
  }

  const handleCloseModalApprovalsNotification = () => {
    setRequiresApproval(false);
  };

  const onOkAFEX = () =>
  {
    setShowAFEXTC(false);
    saveBeneficiary();
  }

  const onCloseAFEX = () =>
  {
    setShowAFEXTC(false);
  }

  let actionButtons = []
  actionButtons.push(<Button type="secondary" onClick={() => onCancelClick()}>
    {getResource('Button.Cancel')}
  </Button>)
  if (currentStep > 0) {
    actionButtons.push(<Button type="primary" onClick={() => onPreviousClick()}>
      {getResource('Previous')}
    </Button>)
  }
  if (currentStep < steps.length - 1) {
    actionButtons.push(<Button type="primary" onClick={() => onNextClick()}>
      {getResource('Next')}
    </Button>)
  }
  if (currentStep === steps.length - 1 && props.canEdit) {
    actionButtons.push(<Button type="primary" onClick={() => onSaveClick()}>
      {getResource('Button.Save')}
    </Button>)
  }

  return (
    <div>
      <Modal
        title={getResource('Js_Message_SubmitSucessful')}
        centered
        visible={sucessModal}
        footer={null}
        onCancel={onCancelClick}
        maskClosable={false}
        className='modal-message-xs'
        zIndex={1021}
        closable={false}
      >
        {message}
        <div className="row center">
          <Button id="confirmation-OK" type="primary" onClick={onCancelClick}>
            {getResource('Button.Ok')}
          </Button>
        </div>
      </Modal>

      <Steps current={currentStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className='screen-container'>
        <div className="row wizard-container">
          <div className='col-md-2 col-xs-0'> </div>
          <div className='col-md-8 col-xs-12'>
            {steps[currentStep].content}
          </div>
          <div className='col-md-2 col-xs-0'> </div>
        </div>

        <div className="row-actions">
          {actionButtons}
        </div>
      </div>
      <ApprovalsNotification
        title={getResource('BeneficiaryApproved')}
        entityName={beneficiary.BeneficiaryName}
        entityType="Beneficiary"
        showApprovalsNotification={requiresApproval}
        contactsAbleToApprove={contactsAbleToApprove}
        onClose={handleCloseModalApprovalsNotification}
        onCloseDetails={onCancelClick}
      />
      <Modal
        title={<h3 className="component-title">{getResource('AlertAFEX')}</h3>}
        centered visible={showAFEXTC} footer={null} onCancel={onCloseAFEX}
        maskClosable={false} width={'50%'} zIndex={1021}>
            <div>
              <div>
                <h4>{getResource('AFEX_Beneficiary_Message')}</h4>
              </div>
          <div className="row-actions">
                    <Button type="primary" onClick={onOkAFEX}>
                      {getResource('Button.Submit')}
            </Button>
                    <Button type="primary" onClick={onCloseAFEX}>
                      {getResource('Button.Decline')}
            </Button>
              </div>
            </div>
          </Modal>
    </div>
  );

};

export default BankAccountScreen;
