import jwt from 'jsonwebtoken';
import { getResource } from '../helpers/ResourcesHelper';

var CryptoJS = require("crypto-js");

//global extend
declare global {
    interface Window {
        REACT_APP_API_URL: string;
        REACT_APP_API_KEY: string;
        REACT_APP_AFEX_REGISTRATIONFORM_URL: string;
        REACT_APP_REGISTRATIONFORM_URL: string;
        REACT_APP_TITLE: string;
        REACT_APP_SESSION_TICK: number;
        REACT_APP_COMPANY: string;
        REACT_APP_COMPANY_PRIVACY_URL: string;
        REACT_APP_USE_NEW_ENCRYPT: boolean;
        REACT_APP_APIDISABLEDATAENCRIPTATION: boolean;
    }
}

const encryptKey = process.env.REACT_APP_ENCRYPTION_KEY;
const useEncryption = window.REACT_APP_USE_NEW_ENCRYPT;
const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;
const apidisabledataencriptation = window.REACT_APP_APIDISABLEDATAENCRIPTATION;

export async function decrypt(cypher: any) {
    if (apidisabledataencriptation) {
        console.log(cypher.data)
        return JSON.parse(cypher.data);
    }

    let responseJSON;
    if (useEncryption) {
        var cipher = cypher.data;

        var key = CryptoJS.enc.Utf8.parse(encryptKey);     // Use Utf8-Encoder. 
        var iv = CryptoJS.enc.Utf8.parse('');                     // Use Utf8-Encoder

        var ciphertext = CryptoJS.enc.Base64.parse(cipher);    // Use Base64-Encoder.       
        var encryptedCP = CryptoJS.lib.CipherParams.create({
            ciphertext: ciphertext,
            formatter: CryptoJS.format.OpenSSL                                     // Optional, but required for encryptedCP.toString() 
        });
        var decryptedWA = CryptoJS.AES.decrypt(encryptedCP, key, { iv: iv });
        var decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);
        responseJSON = await JSON.parse(decryptedUtf8);

    } else {
        var decoded = jwt.decode(cypher.data) as any;
        responseJSON = decoded.response;
    }

    return responseJSON;
}

export async function genericGetWithParameters(apiName:string, params:any) {
    try {
        let sessionID = await localStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        
        if(response.status === 200)
        {
            let responseJWT = await response.json();
            let responseJSON = await decrypt(responseJWT);
            //@ts-ignore
            responseJSON.httpStatusCode = response.status;
            return responseJSON;
        }else{
            let responseJSON = await response.json();
            responseJSON.httpStatusCode = response.status;
            let httpErrorMessage = '';
            switch(response.status)
            {
                case 200:
                    break;
                case 409:
                    httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                    break;
                case 412:
                    httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                    break;
                default:
                    httpErrorMessage = 'An error occurred. Please try again.';
                    break;
            }
            responseJSON.httpErrorMessage = httpErrorMessage;
            return responseJSON;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBodyAndResponseDecoded(method:string, apiName:string, model:any){
    try {
        let sessionID = await localStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
            body: JSON.stringify(model),
        });
        console.log(response)
        let responseJWT = await response.json();
        console.log(responseJWT)
        let responseJSON

        console.group(apiName)
        console.groupEnd();

        if(responseJWT.data !== undefined) {
            responseJSON = await decrypt(responseJWT);
        }
        else {
            responseJSON = responseJWT;
        }
        console.log(responseJSON)
        responseJSON.httpStatusCode = response.status;
        let httpErrorMessage = '';
        switch(response.status)
        {
            case 200:
                break;
            case 409:
                httpErrorMessage = 'This record has been updated by someone else. Please reload the information and try again.';
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                httpErrorMessage = 'An error occurred. Please try again.';
                break;
        }
        responseJSON.httpErrorMessage = httpErrorMessage;
        return responseJSON;

    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithBody(method:string, apiName:string, model:any){
    try {
        let sessionID = await localStorage.getItem('SessionID'); 
        let response = await fetch(apiURL + apiName, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
            body: JSON.stringify(model),
        });
        console.log(response);
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        var httpErrorMessage = undefined;
        switch(response.status)
        {
            case 409:
                httpErrorMessage = getResource('Js_MessageError_Concurrency'); 
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                break;
        }
        responseJson.httpErrorMessage = httpErrorMessage;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericDeleteWithParameters(apiName:string, params:any){
    try {
        let sessionID = await localStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'DELETE',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function genericGetWithParametersNoEncrypted(apiName:string, params:any){
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let query = '';
        if (params != null) {
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
        } 
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'GET',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        let jsonResponse = await response.json();
        jsonResponse.httpStatusCode = response.status;
        var httpErrorMessage = '';
        switch(Number(response.status)){
            case 409:
                httpErrorMessage = getResource('Js_MessageError_Concurrency'); 
                break;
            case 412:
                httpErrorMessage = 'Sorry we cannot process your transaction at this time. Please try again later.';
                break;
            default:
                break;
        }
        jsonResponse.httpErrorMessage = httpErrorMessage;
        return jsonResponse;
    } catch (error) {
        console.error(error);
    }
}

export async function genericCallWithOutBody(apiName:string, params:any){
    try {
        let sessionID = await localStorage.getItem('SessionID');

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');  
        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'POST',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        let responseJson = await response.json();
        responseJson.httpStatusCode = response.status;
        return responseJson;
    } catch (error) {
        console.error(error);
    }
}

export async function validateSession(){
    try {
        let response = await genericGetWithParametersNoEncrypted('Session/Get',{});
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getServerVersion() {
    let responseWeb = await fetch(apiURL + 'Version/GetOnlineVersion', {
            method: 'GET'
        });
    let response = await responseWeb.json();
    response.httpStatusCode = responseWeb.status;
    return response;  
}

export async function genericCallWithParametersNoEncrypted(apiName: string, params: any) {
    try {
        let sessionID = await localStorage.getItem('SessionID');
        let query = ''

        if (params !== null) {
            query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
        }

        let response = await fetch(apiURL + apiName + '?' + query, {
            method: 'PUT',
            headers: {
                'SessionID': sessionID!,
                'APIKey': apiKey!,
            },
        });
        let responseJWT = await response.json();
        responseJWT.httpStatusCode = response.status;
        return responseJWT;
    } catch (error) {
        console.error(error);
    }
}